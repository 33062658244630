import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/Layout/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home/index.vue'),
      },
      {
        path: '/featured-boxes',
        name: 'featured-boxes',
        component: () => import('@/views/Featured-boxes/index.vue'),
      },
      {
        path: '/featured-boxes-item',
        name: 'featured-boxes-item',
        component: () => import('@/views/Featured-boxes-item/index.vue'),
      },
      {
        path: '/forge',
        name: 'forge',
        component: () => import('@/views/Forge/index.vue'),
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/views/personal/index.vue'),
        children: [
          {
            path: '/personal/profile',
            name: 'profile',
            component: () => import('@/views/personal/profile.vue'),
          },
          {
            path: '/personal/deposits',
            name: 'deposits',
            component: () => import('@/views/personal/deposits.vue'),
          },
          {
            path: '/personal/withdrawals',
            name: 'withdrawals',
            component: () => import('@/views/personal/withdrawals.vue'),
          },
          {
            path: '/personal/sales',
            name: 'sales',
            component: () => import('@/views/personal/sales.vue'),
          },
          {
            path: '/personal/pack-history',
            name: 'pack-history',
            component: () => import('@/views/personal/pack-history.vue'),
          },
          {
            path: '/personal/forge-history',
            name: 'forge-history',
            component: () => import('@/views/personal/forge-history.vue'),
          },
          {
            path: '/personal/affiliate',
            name: 'affiliate',
            component: () => import('@/views/personal/affiliate.vue'),
          },
          {
            // 路由重定向
            path: '/personal',
            redirect: {
              path: '/personal/profile',
            }
          }
        ]
      },
      {
        path: '/pay',
        name: 'pay',
        component: () => import('@/views/pay.vue'),
      },
      {
        // 路由重定向
        path: '/',
        redirect: {
          path: '/home',
        }
      }
    ]
  },
 
  // 404 页面
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
  {
    // 路由重定向
    path: '/',
    redirect: {
      path: '/',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 每次路由跳转之后 新页面加载在x轴和y轴的初始位置
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
})

// 全局前置守卫
router.beforeEach((to, from, next) => {

  next(); // 正常跳转

});

export default router
