<template>
  <router-view />
</template>

<script setup>
</script>

<style lang="scss">
#app {
  height: 100%;
  background: rgba(21, 25, 30, 1);
}
</style>
